import { uniq, filter } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { createOrder } from '@app/adapter/order-service';
import { loggedInUserState, userAuthInfoSelector } from '@app/domain/app';
import { orderRegistFormState } from '@app/domain/orders';
import { useProduct } from '@app/hooks/useProduct';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { CategoryName, OrientationValue, Product } from '@app/types/catalog';
import {
  OrderCreation,
  OrderCustomerCreation,
  OrderCustomFields,
  OrderItemCreation,
} from '@app/types/order';
import { Organization } from '@app/types/organization';
import { isError } from '@app/utils/error';
import { generateRandomString } from '@app/utils/pattern';

export const useOrder = () => {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const userInfo = useRecoilValue(userAuthInfoSelector);
  const [orderState] = useRecoilState(orderRegistFormState);
  const [isLoading, setIsLoading] = useState(false);
  const { product, productId: paramId, childProducts } = useProduct();
  const getProduct = useCallback(
    (productId: string) => {
      if (!productId) return undefined;
      return childProducts.find((product) => product.id === productId);
    },
    [childProducts]
  );

  const getChildrenProducts = useCallback(
    (productIds: string[]) => {
      return childProducts.filter((product) => productIds.includes(product.id));
    },
    [childProducts]
  );

  const productIds = useMemo(() => {
    let ids: string[] = [];
    if (product?.customFields.orientationType === OrientationValue.ONLINE) {
      product?.customFields.schedules.forEach((schedule) => {
        if (orderState?.[schedule.date]) {
          ids = [
            ...ids,
            ...(Object.values(orderState[schedule.date]) as string[]),
          ];
        }
      });
    } else if (
      product?.customFields.orientationType === OrientationValue.OFFLINE
    ) {
      const stateIds = Object.keys(orderState?.joinDates || []);
      stateIds.length &&
        stateIds.forEach((id) => {
          if (orderState?.joinDates[id].length) {
            ids = [...ids, id];
          }
        });
    }

    ids = uniq(filter(ids, (value) => !!value));
    return ids;
  }, [
    orderState,
    product?.customFields.orientationType,
    product?.customFields.schedules,
  ]);

  const preparePayload = useCallback(
    (product: Product, orientationGroupKey: string): OrderCreation => {
      const customFields: OrderCustomFields = {
        benefitReceive: [loggedInUser?.customFields?.benefitReceive || ''],
        dates: [],
        orientationGroupKey,
        orientationType: product.customFields.orientationType,
        productCategory: product.customFields.category,
        publishAddressFlag: orderState?.publishAddressFlag === 'checked',
        publishPhoneFlag: orderState?.publishPhoneFlag === 'checked',
        referrerName: orderState?.referrerName || '',
      };
      // 説明会(Admin)の場合はreasonを送らないように
      if (orderState?.[`reason_${product.id}`]) {
        customFields.reason = orderState?.[`reason_${product.id}`];
      }
      //参加する日程を送る
      if (product.customFields.orientationType === OrientationValue.OFFLINE) {
        customFields.dates = orderState?.joinDates;
      } else if (
        product.customFields.orientationType === OrientationValue.ONLINE
      ) {
        if (product.customFields.category === CategoryName.ORIENTATION) {
          // 説明会(Admin)の場合はdatesを送る
          product.customFields.schedules.forEach((schedule) => {
            customFields.dates?.push(schedule.date);
          });
        } else if (
          product.customFields.category === CategoryName.ORIENTATION_SUB
        ) {
          product.customFields.schedules.forEach((schedule) => {
            if (orderState?.[schedule.date]) {
              Object.keys(orderState[schedule.date]).forEach((key) => {
                if (orderState[schedule.date][key] === product.id) {
                  customFields.dates = [
                    ...(customFields.dates || []),
                    schedule.date + ' ' + key.split('~')[0],
                  ];
                }
              });
            }
          });
        }
      }

      const items: OrderItemCreation[] = [
        {
          productId: product.id,
          quantity: 1,
          variantId: product?.variants[0].id,
        },
      ];

      const customer: OrderCustomerCreation = {
        addressLine1: loggedInUser?.addressLine1 || '',
        email: loggedInUser?.email || '',
        name: loggedInUser?.name || '',
        nameKana: loggedInUser?.customFields?.firstNameKana || '',
        phone: loggedInUser?.phoneNumber || '',
      };
      return {
        customFields,
        customer,
        items,
      };
    },
    [loggedInUser, orderState]
  );

  const handleCreateOrder = useCallback(async () => {
    if (!userInfo || !userInfo.accessToken || !userInfo?.fingerPrint) {
      navigate('/login');
      setSnackbar(
        true,
        '予期せぬエラーが発生しました。再度ログインしてください。',
        'error'
      );
      return;
    }

    if (!loggedInUser) {
      navigate('/profile/detail');
      setSnackbar(
        true,
        'ご登録情報に誤りがあります。お手数ですが、登録画面より修正をお願いします。'
      );
      return;
    }

    if (!product || !product.organization || !product?.variants[0]) {
      setSnackbar(
        true,
        '説明会情報の取得に失敗しました。ページをリロードして再度お試しください。'
      );
      return;
    }

    setIsLoading(true);

    try {
      const orientationGroupKey = generateRandomString(24);
      //説明会（Admin）のProductに対してOrder作成
      const parentOrderPromise = (async () => {
        const payload = preparePayload(product, orientationGroupKey);

        return createOrder(
          userInfo.accessToken,
          userInfo.fingerPrint,
          (product.organization as Organization).id,
          payload
        );
      })();
      // ONLINEの場合は説明会サブ（Supply）のProductに対してOrder作成
      // OFFLINEの場合は病院の選択は現地で学生が自由に行うため、Order作成をスキップ
      if (product.customFields.orientationType === OrientationValue.ONLINE) {
        const childrenProducts = getChildrenProducts(productIds);
        const orderPromises = childrenProducts.map(async (childrenProduct) => {
          // Todo: childrenProduct登録途中で、エラーが発生した場合に既に登録してしまったOrderに対する対応を検討
          if (
            !childrenProduct ||
            !childrenProduct.organization ||
            !childrenProduct?.variants[0]
          ) {
            setSnackbar(
              true,
              '説明会情報の取得に失敗しました。ページをリロードして再度お試しください。'
            );
            return;
          }
          const childrenPayload = preparePayload(
            childrenProduct,
            orientationGroupKey
          );

          return createOrder(
            userInfo.accessToken,
            userInfo.fingerPrint,
            (childrenProduct.organization as Organization).id,
            childrenPayload
          );
        });
        await Promise.all([parentOrderPromise, ...orderPromises]);
      } else {
        await Promise.all([parentOrderPromise]);
      }

      navigate(`/orientation/${paramId}/orders/completed`);
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, error.message, 'error');
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    getChildrenProducts,
    loggedInUser,
    navigate,
    paramId,
    preparePayload,
    product,
    productIds,
    setSnackbar,
    userInfo,
  ]);
  return {
    getProduct,
    handleCreateOrder,
    isLoading,
    orderState,
    product,
    productIds,
  };
};
