import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { convertNewLineToBr } from '@app/components/Product/ResultItemCard';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { useOrder } from '@app/hooks/useOrder';
import {
  PUBLISH_ADDRESS_FLAG,
  PUBLISH_PHONE_FLAG,
} from '@app/static/constants';
import { Organization } from '@app/types/organization';
import { getOrientationLabel, getTargetPersonLabel } from '@app/utils/catalog';
import { formatDateTime } from '@app/utils/date';
export function OnlineOrientationOrderConfirm(): ReactElement {
  const navigate = useNavigate();

  const {
    getProduct,
    productIds,
    isLoading,
    handleCreateOrder,
    product,
    orderState,
  } = useOrder();

  return (
    <Stack>
      <HeadBlock />
      <Typography variant="h4" textAlign="center" sx={{ mb: 4 }}>
        説明会応募確認
      </Typography>
      <Typography mb={4} textAlign="center">
        入力内容を確認の上、送信ボタンを押してください。
      </Typography>
      <Typography mb={4}>
        まだ応募は完了していません。以下の入力された内容を確認の上、応募を確定してきださい。
      </Typography>
      {product && (
        <Stack spacing={3}>
          <Stack mb={3}>
            <Typography variant="body2" mb={1}>
              ■参加する説明会
            </Typography>
            <Typography>{product.name}</Typography>
          </Stack>
          <Stack mb={3}>
            <Typography variant="body2" mb={1}>
              ■対象者
            </Typography>
            <Typography>
              {getTargetPersonLabel(product?.customFields?.targetPersons)}
            </Typography>
            <Typography>
              {convertNewLineToBr(product?.customFields.targetPersonNote)}
            </Typography>
          </Stack>
          <Stack mb={3}>
            <Typography variant="body2" mb={1}>
              ■開催場所詳細
            </Typography>
            <Typography>
              {getOrientationLabel(product?.customFields?.orientationType)}
            </Typography>
            <Typography>
              ※応募後にチャットとご登録いただいたメールアドレスへzoomのURLを送付いたします。
            </Typography>
          </Stack>
          <Stack mb={3}>
            <Typography variant="body2" mb={1}>
              ■参加する日時/病院
            </Typography>
            {product.customFields.schedules.map((schedule) => (
              <Stack key={schedule.date}>
                {schedule.times.map((time) => {
                  const productData = getProduct(
                    orderState?.[schedule.date]?.[`${time.start}~${time.end}`]
                  );
                  if (!productData) return null;

                  return (
                    <Stack
                      key={`${schedule.date}-${time.start}-${time.end}`}
                      mb={1}
                    >
                      <Stack direction="row">
                        <Typography>
                          ・{formatDateTime(schedule.date)}
                        </Typography>
                        <Typography ml={2}>
                          {time.start}〜{time.end}
                        </Typography>
                      </Stack>
                      <Typography ml={2}>
                        {(productData?.organization as Organization)?.name}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            ))}
          </Stack>
          <Stack mb={3}>
            <Typography variant="body2" mb={1}>
              ■応募理由
            </Typography>
            {productIds.map((productId) => {
              const childProductData = getProduct(productId);
              const orgName = (childProductData?.organization as Organization)
                ?.name;
              const reason = orderState?.[`reason_${productId}`];
              return (
                <Stack key={productId} mb={1}>
                  <Typography fontWeight="bold">{orgName}</Typography>
                  <Typography>{reason}</Typography>
                </Stack>
              );
            })}
          </Stack>
          <Stack mb={3}>
            <Typography variant="body2" mb={1}>
              ■紹介者
            </Typography>
            <Typography>{orderState?.referrerName}</Typography>
          </Stack>
          <Stack>
            <Typography variant="body2">■その他確認</Typography>
            <Typography variant="body2">
              {orderState?.publishAddressFlag
                ? PUBLISH_ADDRESS_FLAG.ALLOWED
                : PUBLISH_ADDRESS_FLAG.NOT_ALLOWED}
            </Typography>
            <Typography variant="body2">
              {orderState?.publishPhoneFlag
                ? PUBLISH_PHONE_FLAG.ALLOWED
                : PUBLISH_PHONE_FLAG.NOT_ALLOWED}
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              fullWidth
              onClick={handleCreateOrder}
              sx={{ height: '52px' }}
            >
              応募確定
            </LoadingButton>
            <LoadingButton
              color="inherit"
              variant="outlined"
              disabled={isLoading}
              fullWidth
              onClick={() => navigate(-1)}
              sx={{ height: '52px' }}
            >
              入力画面へ戻る
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
