import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getUserOrders } from '@app/adapter/order-service';
import { convertNewLineToBr } from '@app/components/Product/ResultItemCard';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { loggedInUserState, userAuthInfoSelector } from '@app/domain/app';
import { orderRegistFormState } from '@app/domain/orders';
import { useProduct } from '@app/hooks/useProduct';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  ORDER_MAX_COUNT,
  PUBLISH_ADDRESS_FLAG,
  PUBLISH_PHONE_FLAG,
} from '@app/static/constants';
import { Product, ProductSchedule } from '@app/types/catalog';
import { Order } from '@app/types/order';
import { Organization } from '@app/types/organization';
import { getTargetPersonLabel } from '@app/utils/catalog';
import { formatDateTime } from '@app/utils/date';
import { isError } from '@app/utils/error';

type Accumulator = {
  [key: string]: Product[];
};
export function OnlineOrientationOrderRegister(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const userInfo = useRecoilValue(userAuthInfoSelector);
  const {
    childProducts,
    isLoading,
    product,
    productId: paramId,
  } = useProduct();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [orderState, setOrderRegistState] =
    useRecoilState(orderRegistFormState);
  const [orderJoinCount, setOrderJoinCount] = useState<Record<string, number>>(
    {}
  );

  let applyCount = 0;
  const isDisableButtonNext = useMemo(() => {
    if (!orderState || !orderState.checked) return true;
    return product?.customFields.schedules.some((schedule, i) => {
      return schedule.times.some((time, t) => {
        return (
          orderState?.[schedule.date]?.[`${time.start}~${time.end}`] &&
          !orderState[
            `reason_${
              orderState?.[schedule.date]?.[`${time.start}~${time.end}`]
            }`
          ]
        );
      });
    });
  }, [orderState, product?.customFields.schedules]);

  const childProductProductSorted = useMemo(() => {
    return childProducts.reduce((acc, cur) => {
      cur.customFields.schedules.forEach((schedule) => {
        if (!acc[schedule.date]) {
          acc[schedule.date] = [];
        }
        acc[schedule.date].push(cur);
      });
      return acc;
    }, {} as Accumulator);
  }, [childProducts]);

  const getChildrenProducts = (date: string) => {
    if (!childProductProductSorted[date]) {
      return [];
    }
    return childProductProductSorted[date] || [];
  };

  const calculateRemainOrderCount = (product: Product) => {
    return (
      (product.customFields.orderMaxCount || ORDER_MAX_COUNT) -
      (product.customFields.orderCount || 0)
    );
  };

  const setDefaultOrderState = useCallback(async () => {
    const date = product?.customFields.schedules[0].date as string;
    const time = product?.customFields.schedules[0].times[0] as {
      end: string;
      start: string;
    };
    const childrenProducts = getChildrenProducts(date);
    if (
      childrenProducts.length &&
      calculateRemainOrderCount(childrenProducts[0])
    ) {
      setOrderRegistState({
        [date]: {
          [`${time?.start}~${time?.end}`]: childrenProducts[0]?.id,
        },
      });
      await fetchUserJoinedCount([childrenProducts[0]?.organizationId]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const calculateOrderJoinCount = (id: string) => {
    const product = childProducts.find((product) => product.id === id);
    const count = orderJoinCount[(product?.organization as Organization)?.id]
      ? orderJoinCount[(product?.organization as Organization)?.id] + 1
      : 0;
    return count;
  };

  const checkIsDisable = (
    id: string,
    currentDate: string,
    currentTime: string
  ): boolean => {
    for (const date in orderState) {
      for (const timeSlot in orderState?.[date]) {
        if (
          orderState?.[date]?.[timeSlot] === id &&
          (date !== currentDate || timeSlot !== currentTime)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const fetchUserJoinedCount = useCallback(
    async (orgIds: string[]) => {
      if (
        orgIds.length &&
        userInfo?.accessToken &&
        userInfo?.fingerPrint &&
        userInfo?.userId
      ) {
        try {
          let allOrders: Order[] = [];
          let nextLink = '';

          do {
            const response = await getUserOrders(
              userInfo.accessToken,
              userInfo.fingerPrint,
              userInfo.userId,
              {
                '@nextLink': nextLink,
                orgIds,
              }
            );

            allOrders = [...allOrders, ...response.data.value];
            nextLink = response.data['@nextLink'] || '';
          } while (nextLink);

          const orderCount: Record<string, number> = {};
          allOrders.forEach((item) => {
            const org = item.organization as string;
            if (orderCount[org]) {
              orderCount[org]++;
            } else {
              orderCount[org] = 1;
            }
          });

          setOrderJoinCount(orderCount);
        } catch (error) {
          if (isError(error)) {
            setSnackbar(true, error.message);
          }
        }
      }
    },
    [setSnackbar, userInfo]
  );

  const handleChange = useCallback(
    (
      e: React.SyntheticEvent<Element, Event>,
      schedule: ProductSchedule,
      time: {
        end: string;
        start: string;
      },
      sortedChildProduct: Product
    ) => {
      if ((e.target as HTMLInputElement).checked) {
        const selectedOrgIds: string[] = [];
        setOrderRegistState((prev) => {
          const currentState = {
            ...prev,
            [schedule.date]: {
              ...prev?.[schedule?.date],
              [`${time.start}~${time.end}`]: sortedChildProduct.id,
            },
          };

          for (const date in currentState) {
            for (const timeSlot in currentState[date]) {
              const orgId = (
                childProducts?.find(
                  (product) => product.id === currentState[date][timeSlot]
                )?.organization as Organization
              )?.id;
              orgId && selectedOrgIds.push(orgId);
            }
          }

          return currentState;
        });
        void fetchUserJoinedCount(selectedOrgIds);
      }
    },
    [childProducts, fetchUserJoinedCount, setOrderRegistState]
  );

  useEffect(() => {
    if (!orderState) void setDefaultOrderState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState, product]);

  if (isLoading) {
    return <Loading />;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Stack>
      <HeadBlock />
      <Typography variant="h3" textAlign="center" sx={{ mb: 4 }}>
        説明会応募
      </Typography>
      <Stack mb={4}>
        希望の条件をご記入のうえ送信してください
        <Box>
          <Typography component="span" color="error">
            *
          </Typography>
          は必須事項です
        </Box>
      </Stack>
      <Stack mb={4}>
        <Typography>あなたの希望職種</Typography>
        <Typography variant="body2">
          {loggedInUser?.customFields.desiredJob}
        </Typography>
      </Stack>
      <Stack mb={1}>
        <Typography variant="body2">
          ■参加する日時/病院
          <Typography component="span" color="error">
            *
          </Typography>
        </Typography>
        <Stack direction="row">
          <Stack>
            <Typography pl={1}>
              {convertNewLineToBr(product?.customFields.schedules[0].timeNote)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {product &&
        product.customFields.schedules.map((schedule, index) => (
          <Stack key={`schedule_${index + 1}`} mb={4}>
            <Typography mb={1}>{formatDateTime(schedule.date)}</Typography>
            {schedule.times.map((time, i) => (
              <Stack key={`time_${i * 10}`}>
                <Typography
                  variant="body2"
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'neutral.grey',
                    border: '1px solid',
                    borderColor: 'neutral.greyBorder',
                    display: 'flex',
                    height: 40,
                    justifyContent: 'center',
                  }}
                >
                  {time.start}〜{time.end}
                </Typography>
                <RadioGroup name={schedule.date}>
                  {getChildrenProducts(schedule.date).map(
                    (sortedChildProduct, j) => (
                      <Stack
                        key={`children_product_${j}`}
                        direction="row"
                        sx={{
                          alignItems: 'center',
                          border: '1px solid',
                          borderColor: 'neutral.greyBorder',
                          height: 40,
                          justifyContent: 'center',
                          opacity:
                            calculateRemainOrderCount(sortedChildProduct) ===
                              0 ||
                            checkIsDisable(
                              sortedChildProduct.id,
                              schedule.date,
                              `${time.start}~${time.end}`
                            )
                              ? 0.5
                              : 1,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, textAlign: 'center' }}
                        >
                          {
                            (sortedChildProduct.organization as Organization)
                              ?.name
                          }
                        </Typography>
                        <FormControlLabel
                          sx={{
                            borderLeft: '2px solid',
                            borderLeftColor: 'neutral.greyBorder',
                            mr: 0,
                            pr: 1,
                            width: 100,
                          }}
                          value={sortedChildProduct.id}
                          disabled={
                            calculateRemainOrderCount(sortedChildProduct) ===
                              0 ||
                            checkIsDisable(
                              sortedChildProduct.id,
                              schedule.date,
                              `${time.start}~${time.end}`
                            )
                          }
                          checked={
                            orderState?.[schedule.date]?.[
                              `${time.start}~${time.end}`
                            ] === sortedChildProduct.id
                          }
                          onChange={(e) =>
                            handleChange(e, schedule, time, sortedChildProduct)
                          }
                          control={<Radio size="small" />}
                          label={
                            <Typography variant="body2">
                              {calculateRemainOrderCount(sortedChildProduct)}
                              枠有
                            </Typography>
                          }
                        />
                      </Stack>
                    )
                  )}
                  {i > 1 && (
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: 'center',
                        border: '1px solid',
                        borderColor: 'neutral.greyBorder',
                        height: 40,
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, textAlign: 'center' }}
                      >
                        参加しない
                      </Typography>
                      <FormControlLabel
                        sx={{
                          borderLeft: '2px solid',
                          borderLeftColor: 'neutral.greyBorder',
                          mr: 0,
                          pr: 1,
                          width: 100,
                        }}
                        value={`noSelect_${i}`}
                        onChange={(e) => {
                          if ((e.target as HTMLInputElement).checked) {
                            setOrderRegistState((prev) => ({
                              ...prev,
                              [schedule.date]: {
                                ...prev?.[schedule?.date],
                                [`${time.start}~${time.end}`]: '',
                              },
                            }));
                          }
                        }}
                        control={<Radio size="small" />}
                        label=""
                      />
                    </Stack>
                  )}
                </RadioGroup>
              </Stack>
            ))}
          </Stack>
        ))}
      <Typography variant="body2">
        ※開始時間より順に選択してください。
      </Typography>
      <Typography variant="body2">
        ※1日の病院への参加数は２～3病院となります。
      </Typography>
      <Typography variant="body2">
        ※各病院の応募は過去と合わせて3回までです。選択できない病院は過去に2回説明会に応募している病院もしくは、別の日時ですでに選択している病院です。
      </Typography>
      {product?.customFields.schedules.map((schedule, i) =>
        schedule.times.map(
          (time, t) =>
            orderState?.[schedule.date]?.[`${time.start}~${time.end}`] && (
              <Stack key={`schedule-${i}-${t}`} sx={{ mt: 3 }}>
                <Typography variant="body2">
                  ■応募理由{++applyCount}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>

                {calculateOrderJoinCount(
                  orderState?.[schedule.date]?.[`${time.start}~${time.end}`]
                ) > 1 && (
                  <Typography variant="body3" color="error">
                    {
                      (
                        childProducts.find(
                          (product) =>
                            product.id ===
                            orderState?.[schedule.date]?.[
                              `${time.start}~${time.end}`
                            ]
                        )?.organization as Organization
                      ).name
                    }
                    は
                    {calculateOrderJoinCount(
                      orderState?.[schedule.date]?.[`${time.start}~${time.end}`]
                    )}
                    回目の応募です。できるだけ多くの方にご利用いただくために、理由をご記入ください。
                  </Typography>
                )}
                <TextField
                  multiline
                  rows={4}
                  sx={{ mt: 1 }}
                  placeholder="応募する理由を入力してください。&#10;(50文字以上)"
                  onChange={(e) => {
                    setOrderRegistState((prev) => ({
                      ...prev,
                      [`reason_${
                        orderState?.[schedule.date]?.[
                          `${time.start}~${time.end}`
                        ]
                      }`]: e.target.value,
                    }));
                  }}
                />
              </Stack>
            )
        )
      )}
      <Stack sx={{ mt: 3 }}>
        <Typography variant="body2">■紹介者名</Typography>
        <TextField
          multiline
          rows={1}
          sx={{ mt: 1 }}
          placeholder="紹介者名を入力してください。"
          onChange={(e) => {
            setOrderRegistState((prev) => ({
              ...prev,
              referrerName: e.target.value,
            }));
          }}
        />
      </Stack>

      <Stack sx={{ mt: 3 }}>
        <Typography variant="body2">
          ■対象者
          <Typography component="span" color="error">
            *
          </Typography>
        </Typography>
        <Typography variant="body3">
          この説明会の対象者は以下の方です。
        </Typography>
        <Typography variant="body3">
          {getTargetPersonLabel(product?.customFields?.targetPersons)}
        </Typography>
        <Typography variant="body3">
          {convertNewLineToBr(product?.customFields.targetPersonNote)}
        </Typography>
        <FormControlLabel
          onChange={(e) => {
            setOrderRegistState((prev) => ({
              ...prev,
              checked: (e.target as HTMLInputElement).checked ? 'checked' : '',
            }));
          }}
          control={<Checkbox />}
          label="対象者を確認し、応募する。"
        />
        <Stack>
          <Typography variant="body2">■その他確認</Typography>
          <Typography variant="body2">
            病院へ公開する情報を選択ください。
          </Typography>
          <FormControlLabel
            onChange={(e) => {
              setOrderRegistState((prev) => ({
                ...prev,
                publishAddressFlag: (e.target as HTMLInputElement).checked
                  ? 'checked'
                  : '',
              }));
            }}
            control={<Checkbox />}
            checked={orderState?.publishAddressFlag === 'checked'}
            label={
              <Typography variant="body2">
                {PUBLISH_ADDRESS_FLAG.ALLOWED}
              </Typography>
            }
          />
          <FormControlLabel
            onChange={(e) => {
              setOrderRegistState((prev) => ({
                ...prev,
                publishPhoneFlag: (e.target as HTMLInputElement).checked
                  ? 'checked'
                  : '',
              }));
            }}
            control={<Checkbox />}
            checked={orderState?.publishPhoneFlag === 'checked'}
            label={
              <Typography variant="body2">
                {PUBLISH_PHONE_FLAG.ALLOWED}
              </Typography>
            }
          />
        </Stack>
      </Stack>

      <LoadingButton
        variant="contained"
        disabled={isDisableButtonNext}
        loading={false}
        onClick={() => {
          navigate(`/orientation/${paramId}/orders/confirm`);
        }}
        sx={{ borderRadius: 0.5, mt: 2, mx: 'auto', width: 343 }}
      >
        応募
      </LoadingButton>
    </Stack>
  );
}
