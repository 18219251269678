import {
  OrientationValue,
  OrientationValueKey,
  Product,
  ProductTargetPerson as TargetPerson,
  ProductTargetPersonKey as TargetPersonKey,
  SearchCondition,
  TargetPersonLabel,
} from '@app/types/catalog';

export const getSearchResultUrl = (condition: SearchCondition | null) => {
  const options = [];
  if (condition?.categoryId) {
    options.push(`category=${condition.categoryId}`);
  }

  if (condition?.locationIds && condition?.locationIds.length > 0) {
    options.push(`locations=${condition.locationIds.join(',')}`);
  }
  if (condition?.keyword) {
    options.push(`keyword=${encodeURIComponent(condition.keyword.trim())}`);
  }
  if (condition?.orderBy) {
    options.push(`orderBy=${condition.orderBy}`);
  }
  return `/search/services?${options.join('&')}`;
};

export const getOrientationLabel = (value: OrientationValueKey) => {
  switch (value) {
    case OrientationValue.ONLINE:
      return 'オンライン';
    case OrientationValue.OFFLINE:
      return '現地';
    default:
      return '';
  }
};

export const getProductAddressLabel = (product: Product) => {
  switch (product.customFields.orientationType) {
    case OrientationValue.ONLINE:
      return 'オンライン';
    case OrientationValue.OFFLINE:
      return product.addressLine1;
    default:
      return '';
  }
};

export const getTargetPersonLabel = (targetPersons?: string[]) => {
  return (
    (targetPersons || [])
      .map((o) => {
        /**
         *  targetPersonsは以下のような文字列の配列
         * ["veterinaryStudent_6", "petNursingStudent_4", "graduationQualified"]
         */

        const [targetKey, targetYear] = o.split('_');
        switch (targetKey) {
          case TargetPerson.VETERINARY_STUDENT:
          case TargetPerson.PET_NURSING_STUDENT: {
            const label = TargetPersonLabel[targetKey];
            return label + (targetYear ? ` ${targetYear}年生` : '');
          }
        }
        return TargetPersonLabel[o as TargetPersonKey] || '';
      })
      .filter((o) => !!o)
      .join('、') || ''
  );
};
